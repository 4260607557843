<template>
  <div class="main">


    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">

      <div class="textbox">
        <div class="row">
       <img src="../assets/img/map.png" alt="">

        </div>
       
     
      </div>
    </div>
    <!-- body end -->

  </div>
</template>

<script>
// 公共样式

import '../assets/css/common.css'
export default {
  data() {
    return {
      index: -1,
      activeIndex: '3',
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: '首页',
          path: '/',
          id: '1',
        },
        {
          names: '政策资讯',
          path: '/news',
          id: '2',
        },
        {
          names: '产业载体',
          path: '/carrier',
          id: '3',
        },
        {
          names: '企业互动',
          path: '/interaction',
          id: '4',
        },
        {
          names: '金融服务',
          path: '/service',
          id: '5',
        },
        {
          names: '企业服务',
          path: '/enterpriseServices',
          id: '6',
        },
        {
          names: '招聘服务',
          path: '/recruit',
          id: '7',
        },
      ],
      CateList:[],
      Category:'',
      BuildingList:[],
      BuildingSSList:[],
    }
  },

  watch: {
    windowWidth(val) {
      let that = this
      console.log('实时屏幕宽度：', val)
      if (val > 990) {
        that.drawer = false
      }
    },
  },
  methods: {
    


  },
  mounted() {
 
  },
}
</script>


<style scoped  lang="scss">


.textbox {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  // padding-top: 100px;
  overflow: hidden;
}


@media (max-width: 500px) {


  .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
    img{
        width: 100%;
    }
  }
 
}
</style>